<template>
  <div>
    <div class="container max-w-screen-lg item-center mx-auto" id="insurance-detail">
      <div class="my-8 max-w-screen-sm mx-auto">
        <div class="py-4 px-8 w-full bg-primary rounded-t-lg">
          <div class="flex justify-between">
            <div class="flex items-center">
              <img src="@/assets/insurance.svg" class="h-12" alt="" />
              <div class="flex flex-col justify-between text-white ml-4 h-12">
                <p class="font-semibold text-xs">Personal Accident</p>
                <p class="font-medium text-xi">Insurance Non Premium - Rp 10.000/day</p>
                <p class="font-medium text-xi">2 Insured Persons</p>
              </div>
            </div>
            <div class="flex items-end text-white">
              <p class="text-xi font-medium">Coverage Date 04 Aug - 10 Aug 2022</p>
            </div>
          </div>
        </div>
        <div class="py-1.5 text-center bg-ee rounded-b-lg">
          <p class="text-xs font-medium">See Protection Details</p>
        </div>
        <div class="py-4 px-4 text-xs font-semibold">
          <p>Contact Details<span>*</span></p>
          <div
            class="w-full border border-b4 rounded-lg items-center flex justify-between py-2.5 px-4 mt-2 mb-3 cursor-pointer"
            @click="setModalContact">
            <p>Fill in contact details<span>*</span></p>
            <span class="text-primary flex items-center">
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </span>
          </div>
          <!--Overlay Effect-->
          <div class="fixed inset-0 bg-black bg-opacity-70 overflow-y-auto h-full w-full z-50"
            :class="[modalContact ? 'block' : 'hidden' ]" id="modal-contact">
            <div class="w-full h-full absolute" @click="setModalContact"></div>
            <!--modal content-->
            <div class="relative top-1/2 transform -translate-y-1/2 mx-auto max-w-screen-lg rounded-2xl">
              <div class="py-4 px-9 flex items-center justify-between bg-primary rounded-t-2xl">
                <h2 class="text-2xl font-bold text-white">Contact Details</h2>
                <button class="flex items-center p-1 justify-center text-white text-xl" @click="setModalContact">
                  <font-awesome-icon icon="fa-solid fa-x" />
                </button>
              </div>
              <div class="py-8 px-9 bg-white rounded-b-2xl">
                <div class="flex items-center mb-0.5">
                  <p class="text-xs font-semibold">Title<span>*</span></p>
                  <div class="ml-9 flex items-center">
                    <input type="radio" name="title" class="w-4 h-4">
                    <label class="text-base font-semibold ml-4" for="">Mr.</label>
                  </div>
                  <div class="ml-10 flex items-center">
                    <input type="radio" name="title" class="w-4 h-4">
                    <label class="text-base font-semibold ml-4" for="">Mrs.</label>
                  </div>
                </div>
                <div class="flex -mx-4">
                  <div class="w-1/2 px-4">
                    <p class="text-xs font-semibold mb-1.5">Nationality<span>*</span></p>
                    <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
                  </div>
                  <div class="w-1/2 px-4">
                    <p class="text-xs font-semibold mb-1.5">Date of Birth<span>*</span></p>
                    <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
                  </div>
                </div>
                <div class="flex -mx-4 mt-4">
                  <div class="w-1/2 px-4">
                    <p class="text-xs font-semibold mb-1.5">Full Name<span>*</span></p>
                    <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
                  </div>
                  <div class="w-1/2 px-4">
                    <p class="text-xs font-semibold mb-1.5">Identity Number NIK/SIM/Passport/KITAS<span>*</span></p>
                    <div class="flex -mx-2">
                      <input type="text"
                        class="py-2.5 px-5 border border-b4 text-sm font-semibold w-1/3 rounded-lg mx-2">
                      <input type="text"
                        class="py-2.5 px-5 border border-b4 text-sm font-semibold w-2/3 rounded-lg mx-2">
                    </div>
                  </div>
                </div>
                <div class="flex -mx-4 mt-4">
                  <div class="w-1/2 px-4">
                    <p class="text-xs font-semibold mb-1.5">Phone Number<span>*</span></p>
                    <div class="flex items-start -mx-2">
                      <input type="text"
                        class="py-2.5 px-5 border border-b4 text-sm font-semibold w-1/4 rounded-lg mx-2">
                      <div class="w-3/4 px-2">
                        <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
                        <p class="text-xs mt-2 text-rounded font-medium">e.g. +62812345678, for country code (+62)</p>
                        <p class="text-xs mt-0.5 text-rounded font-medium">and mobile no. 0812345678</p>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2 px-4">
                    <p class="text-xs font-semibold mb-1.5">Email<span>*</span></p>
                    <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
                  </div>
                </div>
                <div class="flex justify-end mt-5">
                  <button class="py-2 px-16 bg-primary text-white text-xl font-bold rounded-lg">Save</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal Content End -->
          <p>Insured Details<span>*</span></p>
          <div
            class="w-full border border-b4 rounded-lg items-center flex justify-between py-2.5 px-4 mt-2 mb-3 cursor-pointer">
            <p>Insured 1<span>*</span></p>
            <span class="text-primary flex items-center">
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </span>
          </div>
          <div
            class="w-full border border-b4 rounded-lg items-center flex justify-between py-2.5 px-4 mt-2 mb-3 cursor-pointer">
            <p>Insured 2<span>*</span></p>
            <span class="text-primary flex items-center">
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </span>
          </div>
          <p>Terms and Conditions</p>
          <div class="w-full border border-b4 rounded-lg items-center flex py-2.5 px-4 mt-2 mb-3 cursor-pointer"
            @click="toggleAgree">
            <input type="checkbox" class="w-3" :checked="checkAgree" />
            <div class="ml-2 text-xi font-medium">
              <p>I agree to the insurance Terms & Conditions</p>
              <p class="text-rounded">By checking the box, you agree to all of the consitions</p>
            </div>
          </div>
          <p>Price Details</p>
          <div class="w-full border border-b4 rounded-lg items-center px-2 mt-2 mb-3">
            <div class="px-3 py-1.5 flex justify-between items-center w-full text-base font-semibold">
              <p>Price you pay</p>
              <p class="flex items-center">Rp 120.000<span @click="togglePayment"
                  class="text-rounded flex items-center text-sm justify-center ml-3 cursor-pointer">
                  <font-awesome-icon v-if="openPayment" icon="fa-solid fa-chevron-up" />
                  <font-awesome-icon v-else icon="fa-solid fa-chevron-down" />
                </span></p>
            </div>
            <div class="px-3 py-1.5 flex justify-between items-center w-full text-base font-medium border-b4 border-t"
              :class="openPayment ? 'block' : 'hidden'">
              <p>Personal Accident (2x)</p>
              <p class="flex items-center">Rp 120.000</p>
            </div>
          </div>
          <div class="text-center mt-7 text-lg text-white">
            <button class="py-1.5 px-6 bg-primary rounded-lg font-bold">Payment Method</button>
          </div>
        </div>
      </div>
    </div>
    <ContactInsurance ref="contactInsurance" />
  </div>
</template>

<script>
export default {
  name: 'InsuranceDetail',
  components: {
    ContactInsurance: ()=>import('@/components/layout/ModalContactInsurance.vue')
  },
  data() {
    return {
      openPayment: false,
      checkAgree: false,
    }
  },
  methods: {
    togglePayment: function () {
      this.openPayment = !this.openPayment
    },
    toggleAgree: function () {
      this.checkAgree = !this.checkAgree
    },
    setModalContact: function () {
      this.$refs.contactInsurance.open()
    },
  }
};
</script>
